import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section, Hr, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Главная | Тату-салон Canvas Chronicles
			</title>
			<meta name={"description"} content={"Превращение видений в яркие краски"} />
			<meta property={"og:title"} content={"Главная | Тату-салон Canvas Chronicles"} />
			<meta property={"og:description"} content={"Превращение видений в яркие краски"} />
			<meta property={"og:image"} content={"https://radiantomuse.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://radiantomuse.com/img/2635904.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://radiantomuse.com/img/2635904.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://radiantomuse.com/img/2635904.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://radiantomuse.com/img/2635904.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://radiantomuse.com/img/2635904.png"} />
			<meta name={"msapplication-TileImage"} content={"https://radiantomuse.com/img/2635904.png"} />
			<meta name={"msapplication-TileColor"} content={"https://radiantomuse.com/img/2635904.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 0 0" quarkly-title="Hero-17" transition="background-color 0.2s ease 0s" background="rgba(0, 0, 0, 0) url(https://radiantomuse.com/img/1.jpg) 0% 100% /cover no-repeat scroll padding-box">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
				flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					lg-width="100%"
					display="flex"
					flex-direction="column"
					lg-padding="0px 0 0px 0px"
					lg-margin="0px 0px 50px 0px"
					margin="150px 0px 180px 0px"
					align-items="center"
					padding="0px 0px 100px 0px"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="normal 700 64px/1.2 --fontFamily-sans"
						sm-font="normal 700 42px/1.2 --fontFamily-sans"
						padding="0px 250px 0px 250px"
						text-align="center"
						color="#fff"
						lg-padding="0px 0 0px 0"
					>
						Тату-салон Canvas Chronicles
					</Text>
					<Text
						margin="0px 0px 50px 0px"
						font="--lead"
						color="#8b9197"
						text-align="center"
						padding="0px 90px 0px 90px"
						lg-padding="0px 0 0px 0"
						lg-margin="0px 0px 30px 0px"
					>
						Окунитесь в мир, где искусство выходит за рамки бумаги, а кожа становится холстом. В тату-салоне Canvas Chronicles мы стремимся превратить ваши видения в яркую реальность. Каждое посещение обещает теплый прием, независимо от того, делаете ли вы свою первую татуировку или пополняете свою коллекцию.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-align-items="center"
						sm-align-self="stretch"
					>
						<Button
							margin="0px 15px 0px 0px"
							padding="12px 28px 12px 28px"
							background="--color-green"
							border-radius="8px"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							border-color="--color-green"
							hover-color="--light"
							hover-background="rgba(82, 46, 224, 0)"
							sm-width="100%"
							type="link"
							text-decoration-line="initial"
							href="/contact-us"
							sm-text-align="center"
							sm-max-width="200px"
							sm-align-items="center"
							sm-display="flex"
							sm-justify-content="center"
							border-style="solid"
							border-width="2px"
						>
							Контакты
						</Button>
						<Button
							margin="0px 0 0px 0px"
							padding="12px 28px 12px 28px"
							background="rgba(0, 119, 204, 0)"
							color="--light"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-width="100%"
							type="link"
							href="/about"
							sm-text-align="center"
							sm-max-width="200px"
							sm-display="flex"
							sm-align-items="center"
							sm-justify-content="center"
						>
							О нас
						</Button>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Product-6">
			<Override slot="SectionContent" lg-align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				font="normal 500 56px/1.2 --fontFamily-sans"
				text-align="center"
				lg-width="70%"
				md-width="100%"
				color="--darkL1"
			>
				О нашей студии
			</Text>
			<Text margin="0px 0px 30px 0px" font="normal 300 22px/1.5 --fontFamily-serifGaramond" text-align="center">
			Canvas Chronicles - это больше, чем просто тату-салон, это убежище для творчества и точности. Наши художники - мастера своего дела, каждый из них привносит свой уникальный стиль и опыт, чтобы обеспечить широкий спектр художественных возможностей. Мы придерживаемся самых высоких стандартов безопасности и гигиены, делая ваш опыт одновременно безопасным и впечатляющим.
			</Text>
			<Hr
				min-height="10px"
				margin="0px 0px 50px 0px"
				border-color="--color-darkL1"
				width="40px"
				border-width="2px 0 0 0"
				align-self="center"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				md-margin="0px 0px 50px 0px"
				grid-gap="0 35px"
				lg-grid-gap="16px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="#d9e9fa"
					border-radius="4px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						display="flex"
						flex-direction="column"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
						Исключительный талант
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						Наши художники тщательно отбираются за их артистизм и способность исполнять различные стили.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
						Индивидуальный подход
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						Мы подстраиваем каждый сеанс под ваши потребности, чтобы ваша татуировка стала истинным отражением вашей личной истории.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
						Бескомпромиссная безопасность
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						Мы используем самые современные методы стерилизации и высококачественные чернила, чтобы гарантировать ваше здоровье и безопасность.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				margin="30px 0px 30px 0px"
				grid-template-columns="repeat(2, 1fr)"
				md-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="32.5%"
					lg-width="35%"
					background="#ffdd61"
					border-radius="4px"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
					display="flex"
					flex-direction="column"
				>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						background="#ffdd61"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						display="flex"
						flex-direction="column"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
						Наше стремление к совершенству
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						Наше стремление обеспечить исключительное мастерство татуировки отражается в спокойной, инклюзивной обстановке и тщательном внимании к деталям. Мы понимаем важность вашего решения и предоставляем индивидуальные консультации, чтобы ваше видение было реализовано в полной мере.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="70%"
					display="flex"
					align-items="center"
					flex-direction="column"
					justify-content="center"
					padding="0px 80px 0px 80px"
					lg-width="65%"
					md-width="100%"
					md-margin="0px 0px 15px 0px"
					md-padding="0px 0 0px 0"
					lg-padding="0px 40px 0px 40px"
				>
					<Text margin="0px 0px 0px 0px" font="normal 300 22px/1.5 --fontFamily-serifGaramond" text-align="center">
					Присоединяйтесь к нам в Canvas Chronicles, где каждая татуировка - это история, которая ждет, чтобы ее рассказали. Позвольте нам помочь вам создать постоянный символ вашего уникального путешествия.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});